import SendSMS from "@/src/layouts/others/SendSMS";
import SosmedSection from "@/src/layouts/others/SosmedSection";
import Modal from "./Modal";
import { isMobile } from "react-device-detect";
import { useEffect, useState } from "react";

type Props = {
  show: boolean;
  onClose: () => void;
};

const ModalDownloadApp = ({ show, onClose }: Props) => {
  const [mode, setMode] = useState<"mobile" | "desktop" | null>(null);

  useEffect(() => {
    if (isMobile) {
      setMode("mobile");
    } else {
      setMode("desktop");
    }
  }, []);

  return (
    <>
      <Modal
        show={show}
        onClose={onClose}
        className="md:w-full lg:w-[55.625rem] h-full lg:min-h-[46.75rem] bg-[url('/images/bg-showcase.svg')] bg-cover bg-center bg-no-repeat rounded-3xl lg:rounded-[2.125rem] px-4.5 py-6 md:p-[2.375rem] flex flex-col justify-center items-center "
      >
        <div className="w-full max-w-2xl rounded-3xl bg-white/60 backdrop-blur-sm px-8 py-[2.125rem] flex flex-col items-center">
          <div className="text-4xl/[3.375rem] md:text-title1/[4.5rem] text-neutral-900">
            {mode === "mobile" ? (
              <>
                Get <span className="font-tilt-warp">The App</span>
              </>
            ) : (
              <>
                Get App <span className="font-tilt-warp">Download Link</span>
              </>
            )}
          </div>
          <div className="mt-2.5 w-full">
            <SendSMS
              className="hidden lg:flex"
              containterInputClassName="!max-w-none"
            >
              <div className="mt-2.5 text-neutral-600 text-large-none lg:block hidden">
                Get A Link To Download The App
              </div>
            </SendSMS>
            <SosmedSection className="lg:hidden flex-col gap-7" />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ModalDownloadApp;
