import { AxiosError } from "axios";
import { ErrorWithMessage } from "../utils/globalTypes";

export const mainGif = "/images/gif/gif-1.gif";
export const mainVideo = "/images/video/create-facility-2.mp4";
export const mainImage = "/images/video/download-the-app.svg";

const isErrorWithMessage = (error: unknown): error is ErrorWithMessage => {
  return (
    typeof error === "object" &&
    error !== null &&
    "message" in error &&
    typeof (error as Record<string, unknown>).message === "string"
  );
};

export const toErrorWithMessage = (maybeError: unknown): ErrorWithMessage => {
  if (isErrorWithMessage(maybeError)) return maybeError;

  try {
    return new Error(JSON.stringify(maybeError));
  } catch {
    // fallback in case there's an error stringifying the maybeError
    // like with circular references for example.
    return new Error(String(maybeError));
  }
};

export const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

export const getVideoCover = (url: string, seekTo = 0.0) => {
  return new Promise((resolve, reject) => {
    const videoPlayer = document.createElement("video");
    // const videoPlayer = videoRef?.current!;
    videoPlayer.src = url;
    videoPlayer.crossOrigin = "anonymous";
    videoPlayer.addEventListener("loadedmetadata", () => {
      if (videoPlayer.duration < seekTo) {
        reject("video is too short.");
        return;
      }

      const middleTime = videoPlayer.duration / 2;
      videoPlayer.currentTime = middleTime;

      videoPlayer.addEventListener("seeked", () => {
        const canvas = document.createElement("canvas");
        // const canvas = canvasRef?.current!;
        if (canvas) {
          canvas.width = videoPlayer.videoWidth;
          canvas.height = videoPlayer.videoHeight;
        }

        const ctx = canvas.getContext("2d");
        if (ctx) {
          ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);

          resolve({
            image: canvas.toDataURL(),
            duration: videoPlayer.duration,
          });
        }
      });
    });
  });
};

export const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const phoneRegExp2 =
  /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

export const RECAPTCHA_CLIENT_SITE_KEY =
  process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_CLIENT_SITE_KEY ||
  "6LeTqBwkAAAAAEdR9-89hXwVgwK_bP9HXL1iyxgP";

export const formatPhoneNumber = (text: string) => {
  if (!text) return text;

  let cleaned = text.replace(/\D/g, ""); // remove non-digit characters
  let match = null;

  if (cleaned.length > 0 && cleaned.length < 2) {
    return `(${cleaned}`;
  } else if (cleaned.length === 3) {
    return `(${cleaned}`;
  } else if (cleaned.length > 3 && cleaned.length < 5) {
    match = cleaned.match(/(\d{3})(\d{1,3})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}`;
    }
  } else if (cleaned.length === 6) {
    match = cleaned.match(/(\d{3})(\d{3})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}`;
    }
  } else if (cleaned.length > 6 && cleaned.length < 11) {
    match = cleaned.match(/(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
  } else if (cleaned.length === 11) {
    match = cleaned.match(/(\d{3})(\d{3})(\d{5})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
  }

  return text;
};

export const defaultPhone = {
  id: 231,
  name: "United States",
  dial_code: "+1",
  code: "US",
  image:
    "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/US.svg",
};

export const defaultFormatPhone = {
  formattedValue: "",
  value: "",
  floatValue: 0,
  formValue: "",
};

export const cleanHTML = (html: string) => {
  return html.replaceAll(/<\/?[^>]+(>|$)/gi, "");
};

export const urlRegExp =
  /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;

export const handleErrorResponse = (error: AxiosError, type?: string) => {
  let errorResponse;
  if (error.response && error.response.data) {
    // I expect the API to handle error responses in valid format
    if (type) {
      errorResponse = error.response.status; //code
    } else {
      errorResponse = (error.response.data as any).message;
    }
    // JSON stringify if you need the json and use it later
  } else if (error.request) {
    // TO Handle the default error response for Network failure or 404 etc.,
    errorResponse = error.request.message || error.request.statusText;
  } else {
    errorResponse = error.message;
  }
  return errorResponse;
};

export const loadingGif =
  "https://placehold.co/316x683/F1F5F9/F1F5F9?text=loading";

export const generateRandomNumber = () => {
  return (Math.random() * 10).toFixed(2);
};

export const generateRandomAmount = () => {
  return Math.floor(Math.random() * 555 + 100);
};
