import axios, { AxiosInstance, InternalAxiosRequestConfig } from "axios";
import https from "https";

const httpsAgent = new https.Agent({ rejectUnauthorized: false });

export const createNewClient: () => AxiosInstance = () =>
  axios.create({
    baseURL: process.env.NEXT_PUBLIC_STRAPI_URL,
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${process.env.NEXT_PUBLIC_STRAPI_TOKEN}`,
    },
    httpsAgent,
  });

export const client: AxiosInstance = createNewClient();

client.interceptors.request.use(
  (config: InternalAxiosRequestConfig<any>) => {
    if (config.params !== undefined) {
      const { read } = config.params;
      if (read) {
        config.headers.Authorization = `Bearer ${process.env.NEXT_PUBLIC_STRAPI_TOKEN}`;
      }
    } else if (config.data !== undefined) {
      config.headers.Authorization = `Bearer ${process.env.NEXT_PUBLIC_STRAPI_WRITE_TOKEN}`;
    }

    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

type HookType = (accessToken?: string) => AxiosInstance;

const useAxios: HookType = (accessToken) => {
  client.interceptors.request.use((config) => {
    const newConfig: any = { ...config };

    return newConfig;
  });

  return client;
};

export const { isAxiosError } = axios;

export default useAxios;
