"use client";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

interface Props {
  title: string;
  message: string;
  icon?: "warning" | "error" | "success" | "info";
}

const colors = {
  warning: "#FFB600",
  error: "#EE2F28",
  info: "#00AEFF",
  success: "#7BD62C",
};

const buttons = {
  warning: "bg-warning-500 text-white hover:-bg-warning-600",
  error: "bg-danger-500 text-white hover:-bg-danger-600",
  info: "bg-info-500 text-white hover:-bg-info-600",
  success: "bg-[#3DDB6C] text-white hover:-bg-success-600",
};

const icons = {
  warning: "!bg-warning-200 !text-warning-500",
  error: "!bg-danger-200 !text-danger-500",
  info: "!bg-info-200 !text-info-500",
  success: "!bg-success-200 !text-[#3DDB6C]",
};

const ModalAlert = (data: Props) => {
  const MySwal = withReactContent(Swal);

  return MySwal.fire({
    title: data.title,
    text: data.message,
    icon: data.icon ?? "success",
    allowOutsideClick: false,
    customClass: {
      popup: "!rounded-3xl",
      icon: `${icons[data.icon ?? "success"]} !border-0`,
      confirmButton: `${
        buttons[data.icon ?? "success"]
      } rounded-2lg text-[1rem] leading-[1rem] py-2.5 px-8`,
    },
    buttonsStyling: false,
    confirmButtonColor: colors[data.icon ?? "success"],
    confirmButtonText: "Ok",
  });
};

export const ModalConfirm = async (data: any) => {
  const icons = {
    info: `<svg width="64"
    height="64" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.4" d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" />
    <path d="M12 13.75C12.41 13.75 12.75 13.41 12.75 13V8C12.75 7.59 12.41 7.25 12 7.25C11.59 7.25 11.25 7.59 11.25 8V13C11.25 13.41 11.59 13.75 12 13.75Z" />
    <path d="M12.92 15.6199C12.87 15.4999 12.8 15.3899 12.71 15.2899C12.61 15.1999 12.5 15.1299 12.38 15.0799C12.14 14.9799 11.86 14.9799 11.62 15.0799C11.5 15.1299 11.39 15.1999 11.29 15.2899C11.2 15.3899 11.13 15.4999 11.08 15.6199C11.03 15.7399 11 15.8699 11 15.9999C11 16.1299 11.03 16.2599 11.08 16.3799C11.13 16.5099 11.2 16.6099 11.29 16.7099C11.39 16.7999 11.5 16.8699 11.62 16.9199C11.74 16.9699 11.87 16.9999 12 16.9999C12.13 16.9999 12.26 16.9699 12.38 16.9199C12.5 16.8699 12.61 16.7999 12.71 16.7099C12.8 16.6099 12.87 16.5099 12.92 16.3799C12.97 16.2599 13 16.1299 13 15.9999C13 15.8699 12.97 15.7399 12.92 15.6199Z" />
    </svg>
    `,
    success: `<svg
    width="64"
    height="64"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    >
    <path
    opacity="0.4"
    d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
    />
    <path d="M10.5799 15.5801C10.3799 15.5801 10.1899 15.5001 10.0499 15.3601L7.21994 12.5301C6.92994 12.2401 6.92994 11.7601 7.21994 11.4701C7.50994 11.1801 7.98994 11.1801 8.27994 11.4701L10.5799 13.7701L15.7199 8.6301C16.0099 8.3401 16.4899 8.3401 16.7799 8.6301C17.0699 8.9201 17.0699 9.4001 16.7799 9.6901L11.1099 15.3601C10.9699 15.5001 10.7799 15.5801 10.5799 15.5801Z" />
    </svg>`,
    danger: `<svg width="64"
    height="64" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.4" d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" />
    <path d="M13.0599 11.9999L15.3599 9.69986C15.6499 9.40986 15.6499 8.92986 15.3599 8.63986C15.0699 8.34986 14.5899 8.34986 14.2999 8.63986L11.9999 10.9399L9.69986 8.63986C9.40986 8.34986 8.92986 8.34986 8.63986 8.63986C8.34986 8.92986 8.34986 9.40986 8.63986 9.69986L10.9399 11.9999L8.63986 14.2999C8.34986 14.5899 8.34986 15.0699 8.63986 15.3599C8.78986 15.5099 8.97986 15.5799 9.16986 15.5799C9.35986 15.5799 9.54986 15.5099 9.69986 15.3599L11.9999 13.0599L14.2999 15.3599C14.4499 15.5099 14.6399 15.5799 14.8299 15.5799C15.0199 15.5799 15.2099 15.5099 15.3599 15.3599C15.6499 15.0699 15.6499 14.5899 15.3599 14.2999L13.0599 11.9999Z" />
    </svg>
    `,
    warning: `<svg width="64"
    height="64" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.4" d="M21.76 15.92L15.36 4.4C14.5 2.85 13.31 2 12 2C10.69 2 9.49998 2.85 8.63998 4.4L2.23998 15.92C1.42998 17.39 1.33998 18.8 1.98998 19.91C2.63998 21.02 3.91998 21.63 5.59998 21.63H18.4C20.08 21.63 21.36 21.02 22.01 19.91C22.66 18.8 22.57 17.38 21.76 15.92Z" />
    <path d="M12 14.75C11.59 14.75 11.25 14.41 11.25 14V9C11.25 8.59 11.59 8.25 12 8.25C12.41 8.25 12.75 8.59 12.75 9V14C12.75 14.41 12.41 14.75 12 14.75Z" />
    <path d="M12 18.0001C11.94 18.0001 11.87 17.9901 11.8 17.9801C11.74 17.9701 11.68 17.9501 11.62 17.9201C11.56 17.9001 11.5 17.8701 11.44 17.8301C11.39 17.7901 11.34 17.7501 11.29 17.7101C11.11 17.5201 11 17.2601 11 17.0001C11 16.7401 11.11 16.4801 11.29 16.2901C11.34 16.2501 11.39 16.2101 11.44 16.1701C11.5 16.1301 11.56 16.1001 11.62 16.0801C11.68 16.0501 11.74 16.0301 11.8 16.0201C11.93 15.9901 12.07 15.9901 12.19 16.0201C12.26 16.0301 12.32 16.0501 12.38 16.0801C12.44 16.1001 12.5 16.1301 12.56 16.1701C12.61 16.2101 12.66 16.2501 12.71 16.2901C12.89 16.4801 13 16.7401 13 17.0001C13 17.2601 12.89 17.5201 12.71 17.7101C12.66 17.7501 12.61 17.7901 12.56 17.8301C12.5 17.8701 12.44 17.9001 12.38 17.9201C12.32 17.9501 12.26 17.9701 12.19 17.9801C12.13 17.9901 12.06 18.0001 12 18.0001Z" />
    </svg>
    `,
  } as any;

  const iconColors = {
    info: "#30ACFF",
    success: "#3DDB6C",
    danger: "#EE2F28",
    warning: "#FFB600",
  } as any;

  const btnConfirmColors = {
    info: "bg-secondary-500 hover:bg-secondary-600",
    success: "bg-success-500 hover:bg-success-600",
    danger: "bg-danger-500 hover:bg-danger-600",
    warning: "bg-warning-500 hover:bg-warning-600",
  } as any;

  const cancelConfirmColors = {
    info: "border-secondary-500 hover:border-secondary-600 text-secondary-500 hover:text-secondary-600",
    success:
      "border-success-500 hover:border-success-600 text-success-500 hover:text-success-600",
    danger:
      "border-danger-500 hover:border-danger-600 text-danger-500 hover:text-danger-600",
    warning:
      "border-warning-500 hover:border-warning-600 text-warning-500 hover:text-warning-600",
  } as any;

  return Swal.fire({
    title: `<div class='!text-neutral-900 !text-title3'>${data.title}<div>`,
    html: `<p class='!text-neutral-600 !text-regular-normal'>${data.subtitle}</p>`,
    iconColor: iconColors[data.color ?? "success"],
    iconHtml: icons[data.color ?? "success"],
    showCancelButton: true,
    buttonsStyling: false,
    allowOutsideClick: false,
    confirmButtonText: `
<div class='rounded-2lg py-3 px-3 text-white focus:border-0 border-0 text-center w-[8.156rem] ${
      btnConfirmColors[data.color ?? "success"]
    }'>
I'm Sure
</div>
`,
    cancelButtonText: `<div class='border rounded-2lg py-3 px-3 text-center w-[8.156rem] ${
      cancelConfirmColors[data.color ?? "success"]
    }'>
Cancel
</div>`,
    customClass: {
      popup: "!rounded-3xl",
      title: "!font-bold !-mt-4",
      icon: "!border-0 !mt-4",
      actions: "!space-x-3 flex-row-reverse",
      confirmButton: "focus-visible:!outline-none",
    },
  });
};

export default ModalAlert;
