import "../styles/global.css";
import "../styles/cloud.css";
import "../styles/ck-editor.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "animate.css/animate.min.css";
import type { AppContext, AppProps } from "next/app";
import App from "next/app";
import { deleteCookie, hasCookie, setCookie } from "cookies-next";
import { InfoProps } from "@/src/utils/globalTypes";
import { useEffect, useState } from "react";
import _ from "lodash";
import { DateTime } from "luxon";
import { client } from "@/src/utils/axiosStrapi";
import { useRouter } from "next/router";
import GeneralContext from "@/src/context/GeneralContext";
import ModalDownloadApp from "@/src/component/modal/ModalDownloadApp";

type SualProps = AppProps & {
  general: InfoProps;
};

function MyApp({ Component, pageProps, general }: SualProps) {
  const router = useRouter();
  const popupDownload = router.query["popupDownload"];
  const [modal, setModal] = useState(false);
  const [internalGeneral, setInternalGeneral] = useState<InfoProps | null>(
    general
  );

  useEffect(() => {
    const date = DateTime.now();
    const localDate = localStorage.getItem("general-time") || "";
    if (localDate) {
      const setDate = DateTime.fromISO(localDate);
      if (date.toString() > setDate.toString()) {
        localStorage.removeItem("general-information");
        localStorage.removeItem("footer-group");
        localStorage.removeItem("navbar-group");
      }
    }

    const cek = (localStorage.getItem("general-information") || "") as any; // information
    const cek2 = (localStorage.getItem("footer-group") || "") as any; // footer
    const cek3 = (localStorage.getItem("navbar-group") || "") as any; // navbar
    const cek4 = (localStorage.getItem("sosmed-group") || "") as any; // sosmed
    const cek5 = (localStorage.getItem("device-group") || "") as any; // device
    if (!cek || !cek2 || !cek3 || !cek4 || !cek5) {
      if (
        _.isEmpty(general.information) ||
        _.isEmpty(general.footer) ||
        _.isEmpty(general.navbar) ||
        _.isEmpty(general.socmed) ||
        _.isEmpty(general.device)
      ) {
        deleteCookie("_basic_load");

        // router not reload if they dont have api cms
        const path = router.pathname;
        if (
          !path.startsWith("/_webview") &&
          !path.startsWith("/404") &&
          !path.startsWith("/500")
        ) {
          router.reload();
        }
      } else {
        const newdate = date.plus({ minutes: 30 });
        localStorage.setItem("general-time", newdate.toISO() as string);
        localStorage.setItem(
          "general-information",
          JSON.stringify(general.information)
        );
        localStorage.setItem("footer-group", JSON.stringify(general.footer));
        localStorage.setItem("navbar-group", JSON.stringify(general.navbar));
        localStorage.setItem("sosmed-group", JSON.stringify(general.socmed));
        localStorage.setItem("device-group", JSON.stringify(general.device));

        setInternalGeneral(general);
      }
    } else {
      const information = JSON.parse(cek);
      const footer = JSON.parse(cek2);
      const navbar = JSON.parse(cek3);
      const socmed = JSON.parse(cek4);
      const device = JSON.parse(cek5);

      setInternalGeneral({
        information,
        footer,
        navbar,
        socmed,
        device,
      });
    }

    if (popupDownload && !router.asPath.includes("_webview")) {
      setModal(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [general]);

  if (!internalGeneral) {
    return <></>;
  }

  return (
    <>
      <GeneralContext.Provider value={internalGeneral}>
        <Component {...pageProps} />
        {modal && (
          <ModalDownloadApp
            show={modal}
            onClose={() => {
              setModal(false);
            }}
          />
        )}
      </GeneralContext.Provider>
    </>
  );
}

MyApp.getInitialProps = async (appContext: AppContext) => {
  await App.getInitialProps(appContext);

  let information = {};
  let footer = [];
  let navbar = [];
  let socmed = [];
  let device = {};

  if (
    !hasCookie("_basic_load", {
      req: appContext.ctx.req,
      res: appContext.ctx.res,
    })
  ) {
    const {
      data: { data: info },
    } = await client.get("/information", {
      params: {
        populate: "*",
      },
    });

    const { data: foot } = await client.get(
      "/navigation/render/footer-navigation",
      {
        params: {
          sort: "order:asc",
        },
      }
    );

    const { data: nav } = await client.get(
      "/navigation/render/main-navigation",
      {
        params: {
          sort: "order:asc",
        },
      }
    );

    const {
      data: { data: sosmed },
    } = await client.get("/social-medias", {
      params: {
        populate: "*",
        "sort[0]": "queue:asc",
      },
    });

    const {
      data: { data: dev },
    } = await client.get("/apps-download");

    information = info;
    footer = foot;
    navbar = nav;
    socmed = sosmed;
    device = dev;

    setCookie("_basic_load", true, {
      req: appContext.ctx.req,
      res: appContext.ctx.res,
      expires: DateTime.now().plus({ minutes: 30 }).toJSDate(),
    });
  }

  return {
    general: {
      information,
      footer,
      navbar,
      socmed,
      device,
    },
  };
};

export default MyApp;
