import { Context, createContext } from "react";
import { InfoProps } from "../utils/globalTypes";

export const defaultValue: InfoProps = {
  information: {
    attributes: {
      brand_name: "",
      company_name: "",
      company_address: "",
      support_email: "",
      createdAt: "",
      updatedAt: "",
      logo_color: {
        data: null,
      },
      logo_dark: {
        data: null,
      },
      icon_color: {
        data: null,
      },
      icon_dark: {
        data: null,
      },
      seo: null,
    },
  },
  footer: [],
  navbar: [],
  socmed: [],
  device: {
    attributes: {
      android_name: "",
      android_url: "",
      ios_name: "",
      ios_url: "",
    },
  },
};

const GeneralContext: Context<InfoProps> = createContext(defaultValue);

export default GeneralContext;
