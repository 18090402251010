import { RawAxiosRequestConfig } from "axios";
import BaseModel from "../baseModel";

export type SendLinkProps = {
  phone: string;
  g_recaptcha?: string;
  type: string;
};

export default class SendLink extends BaseModel {
  constructor() {
    super();
  }

  static async send(
    params: SendLinkProps,
    config?: RawAxiosRequestConfig<any> | undefined
  ) {
    const { data } = await new SendLink().request({
      method: "post",
      url: "/send-link",
      data: params,
      ...config,
    });

    return {
      ...data,
    } as any;
  }
}
