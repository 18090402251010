import { Popover } from "@headlessui/react";
import { ReactNode, useEffect, useState } from "react";
import { usePopper } from "react-popper";

type Props = {
  children: ReactNode;
  button: ReactNode;
  className?: string;
  position?: any;
  getStatus?: (e: any) => void;
  afterClick?: boolean;
  btnRef?: any;
  containerClassName?: string;
  btnClassName?: string;
  disabled?: boolean;
};

export const PopCustom = ({
  children,
  button,
  getStatus,
  btnRef,
  containerClassName = "relative",
  btnClassName = "md:w-auto",
  disabled,
}: Props) => {
  return (
    <Popover className={containerClassName}>
      {({ open }) => (
        <>
          <Popover.Button
            ref={btnRef}
            className={`focus-visible:!outline-none w-full ${btnClassName}`}
            onClick={() => getStatus?.(open)}
            disabled={disabled}
          >
            {button}
          </Popover.Button>

          {children}
        </>
      )}
    </Popover>
  );
};

export const PopCustomDiv = ({
  children,
  button,
  getStatus,
  btnRef,
  containerClassName = "relative",
  btnClassName = "md:w-auto",
  disabled,
}: Props) => {
  return (
    <Popover className={containerClassName}>
      {({ open }) => (
        <>
          <Popover.Button
            ref={btnRef}
            className={`focus-visible:!outline-none w-full ${btnClassName}`}
            onClick={() => getStatus?.(open)}
            as="div"
          >
            {button}
          </Popover.Button>

          {children}
        </>
      )}
    </Popover>
  );
};

const Pop = ({
  children,
  button,
  className = "",
  position = "bottom-end",
  getStatus,
  afterClick,
}: Props) => {
  const [referenceElement, setReferenceElement] = useState<any>();
  const [popperElement, setPopperElement] = useState<any>();
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: position,
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 10],
        },
      },
    ],
  });

  useEffect(() => {
    if (popperElement) {
      getStatus?.(false);
    } else {
      getStatus?.(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popperElement]);

  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button
            ref={setReferenceElement}
            className="focus-visible:!outline-none w-full md:w-auto"
            onClick={() => getStatus?.(open)}
          >
            {button}
          </Popover.Button>

          <Popover.Panel
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
            className={className}
          >
            {({ close }) => {
              if (afterClick) {
                close();
              }

              return <>{children}</>;
            }}
          </Popover.Panel>
        </>
      )}
    </Popover>
  );
};

export default Pop;
