import { ChangeEvent, FC, useState } from "react";
import Icon from "../icon/Icon";

type Props = {
  value?: string;
  name: string;
  type?: string;
  label?: string;
  placeholder?: string;
  className?: string;
  icon?: string;
  iconPosition?: "left" | "right";
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onInput?: (e: any) => void;
  onKeyUp?: (e: any) => void;
  onBlur?: (e: any) => void;
  onClick?: (e: any) => void;
  required?: boolean;
  isValid?: boolean;
  isInvalid?: boolean;
  invalidMessage?: string;
  innerRef: any;
  autoComplete?: string;
  defaultValue?: string;
  onClickIcon?: (e: any) => void;
  onFocus?: (e: any) => void;
  autoFocus?: boolean;
  isIconClick?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  zIndex?: string;
  size?: "lg" | "md" | "sm";
};

const iconPositionSizes = {
  left: {
    sm: "left-0 pl-2",
    md: "left-0 pl-2.5",
    lg: "left-0 pl-3",
    distance: {
      sm: "!pl-8",
      md: "!pl-10",
      lg: "!pl-11",
    },
  },
  right: {
    sm: "right-0 pr-2",
    md: "right-0 pr-2.5",
    lg: "right-0 pr-3",
    distance: {
      sm: "!pr-8",
      md: "!pr-10",
      lg: "!pr-11",
    },
  },
};

const sizes = {
  sm: "p-2 text-xs-normal rounded-md",
  md: "p-2.5 text-small-normal rounded-2lg",
  lg: "p-3 text-regular-normal rounded-xl",
} as {
  [key: string]: string;
};

const iconSizes = {
  sm: "16",
  md: "20",
  lg: "24",
} as {
  [key: string]: string;
};

const Input = ({
  value,
  name,
  type = "text",
  label = "Text Label",
  icon,
  iconPosition = "left",
  placeholder = "Placeholder",
  className = "",
  onChange,
  onInput,
  onKeyUp,
  onBlur,
  onClick,
  required = false,
  isValid = false,
  isInvalid = false,
  invalidMessage = "",
  innerRef,
  autoComplete = "on",
  defaultValue,
  onClickIcon,
  onFocus,
  autoFocus,
  isIconClick = false,
  readOnly,
  disabled = false,
  zIndex = "",
  size = "md",
}: Props) => {
  const [isShow, setIsShow] = useState(false);
  let SpesificIcon;
  if (icon) {
    SpesificIcon = Icon[icon];
  } else if (type === "password") {
    if (isShow) {
      SpesificIcon = Icon["EyeSlash"];
    } else {
      SpesificIcon = Icon["Eye"];
    }
  }

  return (
    <>
      <div className={`relative w-full md:w-auto ${zIndex}`}>
        {icon && type !== "password" && (
          <div
            className={`flex absolute inset-y-0 items-center ${
              iconPositionSizes[iconPosition][size]
            } ${isIconClick ? "cursor-pointer" : ""} text-neutral-700`}
            onClick={onClickIcon}
          >
            <SpesificIcon width={iconSizes[size]} height={iconSizes[size]} />
          </div>
        )}

        {type === "password" && (
          <div
            className={`flex absolute inset-y-0 items-center cursor-pointer ${iconPositionSizes["right"][size]} text-neutral-700`}
          >
            <SpesificIcon
              width={iconSizes[size]}
              height={iconSizes[size]}
              onClick={() => setIsShow(!isShow)}
            />
          </div>
        )}

        <input
          ref={innerRef}
          id={`input-${name}`}
          value={value}
          defaultValue={defaultValue}
          name={name}
          type={type !== "password" ? type : isShow ? "text" : "password"}
          className={`${className} block ${
            icon && iconPositionSizes[iconPosition].distance[size]
          } w-full ${sizes[size]} ${
            disabled ? "text-neutral-400" : "text-neutral-800"
          } bg-white border ${
            value ? "border-neutral-300" : "border-neutral-200"
          } focus:outline-none focus:border-neutral-300 focus:ring-0  placeholder:text-neutral-400 ${
            !isValid &&
            isInvalid &&
            invalidMessage &&
            "!border-danger-500 focus:!border-danger-500"
          }`}
          placeholder={placeholder}
          onChange={onChange}
          onKeyUp={onKeyUp}
          onBlur={onBlur}
          onInput={onInput}
          onClick={onClick}
          required={required}
          autoComplete={autoComplete}
          onFocus={onFocus}
          autoFocus={autoFocus}
          readOnly={readOnly || disabled}
        />
      </div>
      {!isValid && isInvalid && invalidMessage && (
        <p className="mt-2 text-danger-500 text-small-none text-left">
          {invalidMessage}
        </p>
      )}
    </>
  );
};

export default Input;
