import { DeviceStrapiProps } from "@/src/utils/globalTypes";
import useGeneral from "@/src/utils/useGeneral";
import Image from "next/image";
import Link from "next/link";
import { useEffect, useState } from "react";

type Props = {
  className?: string;
};

const SosmedSection = ({ className }: Props) => {
  const [dataDevice, setDataDevice] = useState<DeviceStrapiProps | null>(null);
  const { device } = useGeneral();

  useEffect(() => {
    const dataDev = localStorage.getItem("device-group") || "";
    if (dataDev) {
      const getDataDev = JSON.parse(dataDev);
      setDataDevice(getDataDev);
    } else {
      setDataDevice(device);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={`mt-4.5 flex items-center justify-center gap-5 ${className}`}
    >
      <Link
        href={dataDevice?.attributes.ios_url || "#"}
        title={dataDevice?.attributes.ios_name}
      >
        <Image
          src="/images/other/app-store.png"
          alt="App Store | StorageHunt.com"
          unoptimized
          width={0}
          height={0}
          loading="eager"
          className="w-auto rounded-lg"
        />
      </Link>
      <Link
        href={dataDevice?.attributes.android_url || "#"}
        title={dataDevice?.attributes.android_name}
      >
        <Image
          src="/images/other/playstore.png"
          alt="Playstore | StorageHunt.com"
          unoptimized
          width={0}
          height={0}
          loading="eager"
          className="w-auto rounded-lg"
        />
      </Link>
    </div>
  );
};

export default SosmedSection;
