import { client } from "@/src/utils/axios";
import { RawAxiosRequestConfig } from "axios";

export default class BaseModel {
  _baseUrl = "/general";
  _client = client;

  constructor() {
    this._baseUrl = "/general";
  }

  switchBase(url: string) {
    this._baseUrl = url;
  }

  request(config: RawAxiosRequestConfig<any>) {
    return this._client({ ...config, url: `${this._baseUrl}${config.url}` });
  }
}
