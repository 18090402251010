import { ArrowDownBoldIcon } from "@/src/component/icon/SvgIcon";
import Images from "@/src/component/image/Images";
import SelectSearch from "@/src/component/select/SelectSearch";
import {
  defaultFormatPhone,
  defaultPhone,
  formatPhoneNumber,
  RECAPTCHA_CLIENT_SITE_KEY,
  sleep,
  toErrorWithMessage,
} from "@/src/helpers/helper";
import ModalAlert from "@/src/helpers/modalAlert";
import SendLink from "@/src/models/apis/send_link";
import { PhoneCodeProps } from "@/src/utils/globalTypes";
import { isAxiosError } from "axios";
import { useFormik } from "formik";
import { ReactNode, useEffect, useRef, useState } from "react";
import { NumberFormatValues } from "react-number-format";
import * as Yup from "yup";
import PhoneCode from "@/src/helpers/phone_code.json";
import Button from "@/src/component/button/Button";
import {
  GoogleReCaptchaProvider,
  IGoogleReCaptchaConsumerProps,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";
import { useRouter } from "next/router";

type Props = {
  children?: ReactNode;
  className?: string;
  containterInputClassName?: string;
};

const ContentSendSMS = ({
  children,
  className = "",
  containterInputClassName = "",
}: Props) => {
  const router = useRouter();
  const { executeRecaptcha } =
    useGoogleReCaptcha() as IGoogleReCaptchaConsumerProps;
  const [recaptchaActionKey, setRecaptchaActionKey] = useState(1);

  const [loading, setLoading] = useState(false);
  const [expired, setExpired] = useState(0);
  const [phoneCode, setPhoneCode] = useState<PhoneCodeProps>(defaultPhone);
  const [phoneValue, setPhoneValue] = useState<
    NumberFormatValues & { formValue?: string }
  >(defaultFormatPhone);
  const inputPhoneRef = useRef<HTMLInputElement>(null);
  const validationSchema = Yup.object().shape({
    phone: Yup.string().required("Phone is required"),
  });

  const [type, setType] = useState("buyer");

  const formik = useFormik({
    initialValues: {
      phone: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      formik.setSubmitting(true);
      setLoading(true);
      const token = await executeRecaptcha?.(`send_${recaptchaActionKey}`);

      try {
        await SendLink.send({
          ...values,
          g_recaptcha: token,
          type,
        });

        setExpired(30);
        await sleep(1000);
        setPhoneValue(defaultFormatPhone);
        formik.resetForm();
      } catch (error: unknown) {
        if (isAxiosError(error)) {
          if (error.response) {
            const {
              response: { data },
            } = error;
            if (data.expires_in) {
              setExpired(data.expires_in);
            } else {
              ModalAlert({
                title: data.subject || "Oops!!!",
                message: data.message || "Error!!!",
                icon: "error",
              });
            }
          }
        } else {
          const msg = toErrorWithMessage(error).message;
          ModalAlert({
            title: "Oops!!!",
            message: msg || "Error!!!",
            icon: "error",
          });
        }
      } finally {
        setLoading(false);
        setRecaptchaActionKey((_val) => _val + 1);
      }

      formik.setSubmitting(false);
    },
  });

  useEffect(() => {
    if (!expired) return;
    const intervalId = setInterval(() => {
      const checkTimeLeft = expired;
      setExpired(checkTimeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [expired]);

  useEffect(() => {
    const path = router.pathname;
    if (path.includes("become-seller")) {
      setType("facility");
    } else {
      setType("buyer");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <form
        id="form-contact"
        onSubmit={formik.handleSubmit}
        className={`w-full flex flex-col items-center ${className}`}
      >
        {children ? (
          children
        ) : (
          <div className="mt-3.5 text-large-none text-neutral-600">
            Get A Link To Download The App
          </div>
        )}
        <div className={`mt-3.5 w-full max-w-xl ${containterInputClassName}`}>
          <div className="w-full relative bg-white py-3 px-4.5 shadow-lv3 rounded-full flex flex-items-center gap-2 justify-between">
            <div className="w-full flex flex-col justify-center">
              <div className="flex items-center gap-2 w-full relative">
                <SelectSearch
                  onChangeValue={(e) => {
                    const val = e as PhoneCodeProps;
                    setPhoneCode(val);
                    if (phoneValue.value) {
                      setPhoneValue({
                        ...phoneValue,
                        formValue: `${val.dial_code}${phoneValue.value}`,
                      });

                      formik.setFieldValue(
                        "phone",
                        `${val.dial_code}${phoneValue.value}`
                      );
                    }
                  }}
                  value={{
                    id: phoneCode.id,
                    name: phoneCode.name,
                  }}
                  buttonElement={
                    <div className="flex items-center gap-1.5 cursor-pointer">
                      <div className="w-8">
                        <Images
                          src={phoneCode.image}
                          className="w-full"
                          alt="phone-code"
                        />
                      </div>
                      <div className="flex items-center gap-1.5">
                        <div className="text-regular-tight text-neutral-500">
                          {phoneCode.dial_code}
                        </div>

                        <ArrowDownBoldIcon
                          width="18"
                          height="18"
                          className="text-neutral-900"
                        />
                      </div>
                    </div>
                  }
                  textClassName="text-small-none"
                  options={PhoneCode.map((e, key) => ({
                    id: key + 1,
                    ...e,
                  }))}
                  panelClassName="z-20 min-w-max md:min-w-0 !left-4"
                  containerClassName="!w-auto leading-[0]"
                  placeholder="Search country"
                  additionalValue={{
                    type: "country",
                    value: ["image", "dial_code"],
                  }}
                />
                <input
                  ref={inputPhoneRef}
                  value={phoneValue.formattedValue}
                  name={"phone"}
                  type="text"
                  className={`block w-full text-neutral-800"
          bg-white focus:outline-none focus:ring-0  placeholder:text-neutral-400`}
                  placeholder={"(xxx) xxx-xxxx"}
                  onChange={(e) => {
                    const val = e.currentTarget.value;
                    const cleanedVal = val.replace(/\D/g, "");
                    if (cleanedVal.length > 11) {
                      return false;
                    }
                    const formatPhone = formatPhoneNumber(cleanedVal);

                    setPhoneValue({
                      floatValue: Number(cleanedVal),
                      formattedValue: formatPhone,
                      value: cleanedVal,
                      formValue: `${phoneCode.dial_code}${cleanedVal}`,
                    });

                    if (cleanedVal.length === 0) {
                      formik.setFieldValue("phone", "");
                    } else {
                      formik.setFieldValue(
                        "phone",
                        `${phoneCode.dial_code}${cleanedVal}`
                      );
                    }
                  }}
                />
              </div>

              {formik.touched.phone && formik.errors.phone && (
                <p className="mt-2 text-danger-500 text-small-none text-left">
                  {formik.errors.phone}
                </p>
              )}
            </div>
            <div className="w-full max-w-[10.25rem]">
              <Button
                type="submit"
                size="lg"
                className={`!rounded-full ${
                  loading || expired > 0
                    ? "!bg-neutral-200 !text-neutral-400 font-medium"
                    : "!bg-neutral-900 !text-white "
                }`}
                disabled={
                  (!formik.isValid && !!formik.submitCount) ||
                  loading ||
                  expired > 0
                }
              >
                {loading
                  ? "Loading..."
                  : expired > 0
                  ? expired
                  : "Text Me a Link"}
              </Button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

const SendSMS = (params: Props) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_CLIENT_SITE_KEY}>
      <ContentSendSMS {...params} />
    </GoogleReCaptchaProvider>
  );
};

export default SendSMS;
