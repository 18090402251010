import { MouseEventHandler, ReactNode } from "react";

export type BtnProps = {
  children: ReactNode;
  type?: "button" | "reset" | "submit";
  size?: "xs" | "sm" | "md" | "lg";
  color?: "primary" | "success" | "alert" | "warning" | "info";
  className?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  form?: string;
};

const sizes = {
  xs: "p-2 rounded-lg text-tiny-tight",
  sm: "p-2 rounded-lg text-xs-normal",
  md: "p-3 rounded-2lg text-small-tight",
  lg: "p-4 rounded-xl text-regular-none",
} as {
  [key: string]: string;
};

const colors = {
  primary: "text-white bg-primary-500",
  success: "text-white bg-success-500",
  alert: "text-white bg-danger-500",
  warning: "text-white bg-warning-500",
  info: "text-white bg-info-500",
} as {
  [key: string]: string;
};

const hoverColors = {
  primary: "hover:bg-primary-600",
  success: "hover:bg-success-600",
  alert: "hover:bg-danger-600",
  warning: "hover:bg-warning-600",
  info: "hover:bg-info-600",
} as {
  [key: string]: string;
};

const disabledColor = "!text-neutral-400 !bg-neutral-200 cursor-not-allowed";

export default function Button({
  children,
  type = "button",
  size = "md",
  color = "primary",
  className,
  onClick,
  disabled,
  form,
}: BtnProps) {
  return (
    <>
      <button
        form={form}
        type={type}
        className={`w-full flex flex-row items-center justify-center gap-2 hover-anime font-medium ${
          sizes[size]
        } ${colors[color]} ${hoverColors[color]} ${className} ${
          disabled ? disabledColor : ""
        }`}
        onClick={onClick}
        disabled={disabled}
      >
        {children}
      </button>
    </>
  );
}
