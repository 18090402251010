interface Props {
  src?: string;
  alt: string;
  className?: string;
  onLoad?: (e: any) => void;
  onClick?: () => void;
  wrapperClassName?: string;
}

export const noneSrc = "https://www.btklsby.go.id/images/placeholder/basic.png";

const Images = ({
  src,
  alt,
  className,
  onLoad,
  onClick,
  wrapperClassName,
}: Props) => {
  const link = src ? src : noneSrc;
  return (
    /* eslint-disable @next/next/no-img-element */
    <img
      src={link}
      alt={alt}
      className={className}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null; // prevents looping
        currentTarget.src = noneSrc;
      }}
      onLoad={onLoad}
      onClick={onClick}
    />
  );
};

export const Logo = ({
  className,
  isDark,
  src,
  alt = "StorageHunt.com",
}: {
  className?: string;
  isDark?: boolean;
  src?: string;
  alt?: string;
}) => {
  const light = "/images/logo/logo.svg";
  const dark = "/images/logo/logo-dark.svg";
  return (
    /* eslint-disable @next/next/no-img-element */
    <img
      src={src ? src : isDark ? dark : light}
      alt={alt}
      className={className}
    />
  );
};

export default Images;
